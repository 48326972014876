import OnlineBanking from "../images/online_banking.png";
import CashPayments from "../images/cash_payments.png";
import CreditCard from "../images/credit_card.jpg";
import Spei from "../images/spei.png";
import Oxxo from "../images/oxxo.png";
import Paynet from "../images/paynet.png";
import Todito from "../images/todito.png";
import Efecty from "../images/efecty.png";
import PSE from "../images/pse.png";
import Khipu from "../images/khipu.png";
import OnlineBankingM from "../images/online_mobile.png";
import CashPaymentsM from "../images/cash_mobile.png";
import EfectyM from "../images/efecty_mobile.png";
import PseM from "../images/pse_mobile.png";
import KhipuM from "../images/khipu_mobile.png";
import ToditoM from "../images/todito_mobile.png";
import ToditoCash from "../images/todito_cash.png";
import WalletBra from "../images/wallet_bra.png";
import Paypal from "../images/paypal.png";
import Ame from "../images/ame.png";
import Picpay from "../images/picpay.png";
import Boleto from "../images/boleto.png";
import BoletoRapidao from "../images/boleto_rapidao.png";
import DepositExpress from "../images/deposit_express.png";
import Lottery from "../images/lottery.png";
import Pix from "../images/pix.png";
import i18n from "../language/i18n";

const channelConfig = ["Cash", "BankTransfer", "Khipu", "Wallet"]; // methods that have channels to be choosen

// fot wallets in MEX and BR
const walletLogo = {
  MEX: { pc: Todito, mobile: ToditoM },
  BRA: { pc: WalletBra, mobile: WalletBra },
};

const walletChannels = {
  MEX: { Todito: ToditoCash },
  BRA: { PayPal: Paypal, AME: Ame, PicPay: Picpay },
};

const countryCode2TaxIdName = {
  MEX: { code: "RFC", text: "RFC" },
  CHL: { code: "RUT/RUN", text: "RFC" },
  COL: { code: "TIN", text: "RFC" },
  PER: { code: "RUC", text: "RFC" },
  ECU: { code: "RUC", text: "RFC" },
  BRA: { code: "CPF/CNPJ", text: "RFC" },
  PAN: { code: "CIP", text: "RFC" },
  CRI: { code: "CDI", text: "Cédula de Identidad" },
  SLV: { code: "DUI ", text: "DUI" },
  GTM: { code: "DPI", text: "DPI" },
  NIC: { code: "CIP", text: "CIP" },
};

const countryCode3to2 = {
  MEX: "MX",
  CHL: "CL",
  COL: "CO",
  PER: "PE",
  ECU: "EC",
  BRA: "BR",
  PAN: "PA",
  CRI: "CR",
  SLV: "SV",
  GTM: "GT",
  NIC: "NI",
};

const methodConfig = (region) => {
  return {
    Wallet: {
      info: {
        imageMobile: walletLogo[region]?.mobile,
        image: walletLogo[region]?.pc,
        text: i18n.t("wallet"),
      },
    },
    // Brazil
    Boleto: {
      info: {
        imageMobile: Boleto,
        image: Boleto,
        text: "Boleto",
      },
    },
    BoletoRapido: {
      info: {
        imageMobile: BoletoRapidao,
        image: BoletoRapidao,
        text: "Boleto Rápido",
      },
    },
    DepositExpress: {
      info: {
        imageMobile: DepositExpress,
        image: DepositExpress,
        text: "Depósito Bancário",
      },
    },
    Lottery: {
      info: {
        imageMobile: Lottery,
        image: Lottery,
        text: "Pagamento na Lotérica",
      },
    },
    PIX: {
      info: {
        imageMobile: Pix,
        image: Pix,
        text: "PIX",
      },
    },
    // Mexico
    Paynet: {
      info: {
        imageMobile: Paynet,
        image: Paynet,
        text: "Paynet",
      },
    },
    OXXO: {
      info: {
        imageMobile: Oxxo,
        image: Oxxo,
        text: i18n.t("oxxo"),
      },
    },
    SPEI: {
      info: {
        imageMobile: Spei,
        image: Spei,
        text: "SPEI",
      },
    },
    CreditCard: {
      info: {
        imageMobile: CreditCard,
        image: CreditCard,
        text: i18n.t("credit_card"),
      },
    },
    Cash: {
      info: {
        imageMobile: CashPaymentsM,
        image: CashPayments,
        text: i18n.t("cash_payments"),
      },
    },
    BankTransfer: {
      info: {
        imageMobile: OnlineBankingM,
        image: OnlineBanking,
        text: i18n.t("online_banking"),
      },
    },
    PSE: {
      info: {
        imageMobile: PseM,
        image: PSE,
        text: "PSE",
      },
    },
    Efecty: {
      info: {
        imageMobile: EfectyM,
        image: Efecty,
        text: "Efecty",
      },
      faq: [
        { q: i18n.t("efecty_faq1q"), a: i18n.t("efecty_faq1a") },
        { q: i18n.t("efecty_faq2q"), a: i18n.t("efecty_faq2a") },
        { q: i18n.t("efecty_faq3q"), a: i18n.t("efecty_faq3a") },
      ],
      video: [
        // {
        //   title: "Como pagar com banco online",
        //   source: "https://www.youtube.com/embed/74a9n3y2ClU",
        // },
      ],
    },
    Khipu: {
      info: {
        imageMobile: KhipuM,
        image: Khipu,
        text: "Khipu",
      },
      faq: [
        { q: i18n.t("khipu_faq1q"), a: i18n.t("khipu_faq1a") },
        { q: i18n.t("khipu_faq2q"), a: i18n.t("khipu_faq2a") },
        { q: i18n.t("khipu_faq3q"), a: i18n.t("khipu_faq3a") },
      ],
    },
  };
};

// add every translation here to make language changes effect on
function fill() {
  methodConfig().Cash.info.text = i18n.t("cash_payments");
  methodConfig().BankTransfer.info.text = i18n.t("online_banking");
  methodConfig().CreditCard.info.text = i18n.t("credit_card");
  methodConfig().OXXO.info.text = i18n.t("oxxo");
  methodConfig().Wallet.info.text = i18n.t("wallet");

  methodConfig().Efecty.faq[0].q = i18n.t("efecty_faq1q");
  methodConfig().Efecty.faq[0].a = i18n.t("efecty_faq1a");
  methodConfig().Efecty.faq[1].q = i18n.t("efecty_faq2q");
  methodConfig().Efecty.faq[1].a = i18n.t("efecty_faq2a");
  methodConfig().Efecty.faq[2].q = i18n.t("efecty_faq3q");
  methodConfig().Efecty.faq[2].a = i18n.t("efecty_faq3a");

  methodConfig().Khipu.faq[0].q = i18n.t("khipu_faq1q");
  methodConfig().Khipu.faq[0].a = i18n.t("khipu_faq1a");
  methodConfig().Khipu.faq[1].q = i18n.t("khipu_faq2q");
  methodConfig().Khipu.faq[1].a = i18n.t("khipu_faq2a");
  methodConfig().Khipu.faq[2].q = i18n.t("khipu_faq3q");
  methodConfig().Khipu.faq[2].a = i18n.t("khipu_faq3a");
}

i18n.on("languageChanged", (language) => {
  fill();
});

export {
  methodConfig,
  channelConfig,
  countryCode2TaxIdName,
  countryCode3to2,
  walletChannels,
};
