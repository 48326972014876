import React from "react";
import { useTranslation } from "react-i18next";
import Back from "../../images/back.png";
import "./PrivacyPolicy.scss";
function PrivacyPolicy({ back = () => {} }) {
  const { t } = useTranslation();
  return (
    <div className="privacyPolicy">
      <div className="privacyPolicy__content">
        <img
          className="privacyPolicy__content__back"
          src={Back}
          alt=""
          onClick={back}
        />
        <div>
          <h1>{t("privacy.policy")}</h1>
          <p>{t("strategy.brief")}</p>
          <p>{t("policy.purpose")}</p>
          <p>{t("note")}</p>
          <h2>{t("we.collect")}</h2>
          <p>{t("collet.situation")}</p>
          <ul>
            <li>{t("open.pagsmile.account")}</li>
            <li>{t("use.our.website")}</li>
            <li>{t("close.the.deal")}</li>
            <li>{t("third.party.use")}</li>
            <li>{t("communicate")}</li>
          </ul>
          <h2>{t("how.use.your.info")}</h2>
          <p>{t("use.your.data.reasons")}</p>
          <ul>
            <li>{t("provide.pagsmile.service")}</li>
            <li>{t("fraud.prevention")}</li>
            <li>{t("fulfill.our.law")}</li>
            <li>{t("other.business.needs")}</li>
          </ul>
          <h2>{t("how.spread.your.message")}</h2>
          <p>{t("privacy.protection")}</p>
          <ul>
            <li>{t("launch.a.transaction")}</li>
            <li>{t("transaction.processing")}</li>
            <li>{t("employing.third.purpose")}</li>
            <li>{t("law.required")}</li>
            <li>{t("law.required")}</li>
          </ul>
          <h2>{t("how.protect.your.information")}</h2>
          <p>{t("ssl.technology")}</p>
          <p>{t("view.https")}</p>
          <h2>{t("your.information.choices")}</h2>
          <p>{t("you.have.right")}</p>
          <ul>
            <li>{t("modify.your.message")}</li>
            <li>{t("delete.your.information")}</li>
          </ul>
          <p>{t("contact.us.delete.info")}</p>
          <h2>{t("pagsmile.user.information")}</h2>
          <p>{t("not.provide.your.private_info")}</p>
          <h2>{t("cookies")}</h2>
          <p>{t("cookies.introduction")}</p>
          <p>{t("third.cookies")}</p>
          <p>{t("storage.cookies")}</p>
          <p>{t("from.third.cookies")}</p>
          <p>{t("user.Following.cookies")}</p>
          <h2>{t("strictly.session")}</h2>
          <p>{t("cookies.effect")}</p>
          <h2>{t("cookies.performance")}</h2>
          <p>{t("cookies.specific.purpose")}</p>
          <h2>{t("functional.cookies")}</h2>
          <p>{t("cookie.recorded.information")}</p>
          <h2>{t("analytical.cookies")}</h2>
          <p>{t("cookies.benefit")}</p>
          <h2>{t("consent2exclusion.cookies")}</h2>
          <p>{t("agree.cookies")}</p>
          <h2>{t("contact")}</h2>
          <p>{t("have.questions.ask.me")}</p>
          <h2>{t("change.privacy.policy")}</h2>
          <p>{t("change.privacy.policy.anytime")}</p>
          <p>{t("last.updated")}</p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
