import React from "react";
import ShowHideBox from "../showHideBox/ShowHideBox";
import "./Faq.scss";
import { connect } from "react-redux";
import { methodConfig } from "../../utils/configurations";

function Faq(props) {
  return (
    <div className="faq">
      <p className="faq__title">
        Frequently Asked <span>Questions</span>{" "}
      </p>
      {props.tradeInfo?.channel_info_list?.map((item) => {
        return methodConfig()[item.method]?.faq?.map((item) => (
          <ShowHideBox className="faq__box" title={item.q} content={item.a} />
        ));
      })}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
